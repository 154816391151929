import * as React from "react"
import Layout from "../components/layout"
import Retail from "../components/Industries/Retail"






const retail = () => {
  return (
  <Layout>
   <Retail/>
  </Layout>
)
};
export default retail;
