import React from "react"
import retailimage from "../../assets/images/banners/industry-images/retailing.jpg"
import offgrid from "../../assets/images/icons/mitech-box-image-style-06-image-01-120x120.webp"
import providing from "../../assets/images/icons/mitech-box-image-style-06-image-02-120x120.webp"
import billing from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"
const Retail = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img retail"
          data-bg=""
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white"> Retail Solutions</h1>
                  <h5 className="font-weight--normal text-white">
                    Aamanto offers comprehensive e-commerce solutions to help
                    you strengthen your business models, increase sales, and
                    establish a strong market presence.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
            <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">
                    Our Industry Solution
                  </h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Retailing <span className="text-color-primary">Solutions</span></h2>
                  Aamanto is the go-to solutions provider for merchants
                  hit by ecommerce because of its full-stack development
                  abilities. We'll assist you in managing inventory,
                  avoiding stock-outs, and cultivating a long-term
                  customer experience. We deliver services in the
                  following categories:
                  <div className="mt-30 title-wrap">
                    <ol className="retail-list" style={{ columns: "2" }}>
                      <li>Shipping/Freight Management</li>
                      <li>Shopping Cart Integration</li>
                      <li>Payment Gateway Integration</li>
                      <li>Content Management</li>
                      <li>Database Management</li>
                      <li>Order Management</li>
                      <li>Category Management</li>
                      <li>Customer Management</li>
                      <li>Product Management</li>
                      <li>Social Media Integration</li>
                      <li>Track & Trace</li>
                      <li>Supplier Relationship Management</li>
                      <li>Contactless Cards</li>
                      <li>POS Integration</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={retailimage} alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

          
            <!--=========== About Company Area End ==========-->
            
            <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">We Excel In Delivering <span className="text-color-primary">Optimal Services.</span></h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Shipping/Freight Management</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Shopping Cart Integration</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Shopping Cart Integration</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Content Management</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Track & Trace</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Supplier Relationship Management
                      </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Database Management </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pb-5">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Order Management</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Category Management</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Customer Management</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Product Management</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Social Media Integration</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Contactless Cards</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> POS Integration</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}

export default Retail
